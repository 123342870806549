.container {
  background: #2e2e2e;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  z-index: 20000;
}

.fixedContainer {
  position: fixed;
  width: 100vw;
}

.logoJusto img {
  width: 55px;
}

.actions {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.25px;
  color: #ffffff;
  display: flex;
}

.actions a {
  color: white;
}

.edit {
  margin-right: 16px;
}
